// Generated by Framer (90417e1)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getLoadingLazyAtYPosition, Image, useComponentViewport, useLocaleInfo, useVariantState, withCSS, withFX, withOptimizedAppearEffect } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
const ImageWithFXWithOptimizedAppearEffect = withOptimizedAppearEffect(withFX(Image));

const cycleOrder = ["OZtYwI1bw", "GcKu06QKt", "tnyKPtcg4", "DxUcpVEIi"];

const serializationHash = "framer-hRGI0"

const variantClassNames = {DxUcpVEIi: "framer-v-41xfsp", GcKu06QKt: "framer-v-1ue9uzi", OZtYwI1bw: "framer-v-4ts3fh", tnyKPtcg4: "framer-v-muk5xr"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const transition1 = {damping: 60, delay: 0, mass: 1.2, stiffness: 200, type: "spring"}

const animation = {opacity: 1, rotate: 0, rotateX: 0, rotateY: 0, scale: 1, skewX: 0, skewY: 0, transformPerspective: 1200, transition: transition1, x: 0, y: 0}

const animation1 = {opacity: 0.001, rotate: 0, rotateX: 0, rotateY: 0, scale: 0.5, skewX: 0, skewY: 0, transformPerspective: 1200, x: 0, y: 0}

const transition2 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {"Variant 1": "OZtYwI1bw", "Variant 2": "GcKu06QKt", "Variant 3": "tnyKPtcg4", "Variant 4": "DxUcpVEIi"}

const getProps = ({height, id, image, width, ...props}) => { return {...props, JNrqPseRc: image ?? props.JNrqPseRc ?? {src: "https://framerusercontent.com/images/pPKaFkuZHNJyuJ9odfxmKelPc.png?scale-down-to=512", srcSet: "https://framerusercontent.com/images/pPKaFkuZHNJyuJ9odfxmKelPc.png 813w"}, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "OZtYwI1bw"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;image?: {src: string; srcSet?: string}; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, JNrqPseRc, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "OZtYwI1bw", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition2}><ImageWithFXWithOptimizedAppearEffect {...restProps} {...gestureHandlers} __framer__presenceAnimate={animation} __framer__presenceInitial={animation1} __perspectiveFX={false} __smartComponentFX __targetOpacity={1} background={{alt: "", fit: "fill", intrinsicHeight: 872, intrinsicWidth: 813, loading: getLoadingLazyAtYPosition((componentViewport?.y || 0)), pixelHeight: 872, pixelWidth: 813, sizes: componentViewport?.width || "100vw", ...toResponsiveImage(JNrqPseRc)}} className={cx(serializationHash, ...sharedStyleClassNames, "framer-4ts3fh", className, classNames)} data-framer-appear-id={"4ts3fh"} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"OZtYwI1bw"} optimized ref={ref ?? ref1} style={{borderBottomLeftRadius: 12, borderBottomRightRadius: 12, borderTopLeftRadius: 12, borderTopRightRadius: 12, ...style}} {...addPropertyOverrides({DxUcpVEIi: {"data-framer-name": "Variant 4"}, GcKu06QKt: {"data-framer-name": "Variant 2"}, tnyKPtcg4: {"data-framer-name": "Variant 3"}}, baseVariant, gestureVariant)}/></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-hRGI0.framer-v48muu, .framer-hRGI0 .framer-v48muu { display: block; }", ".framer-hRGI0.framer-4ts3fh { height: 436px; overflow: visible; position: relative; width: 406px; }", ".framer-hRGI0.framer-v-1ue9uzi.framer-4ts3fh, .framer-hRGI0.framer-v-muk5xr.framer-4ts3fh, .framer-hRGI0.framer-v-41xfsp.framer-4ts3fh { aspect-ratio: 0.932500033793582 / 1; height: var(--framer-aspect-ratio-supported, 436px); }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 435.5
 * @framerIntrinsicWidth 406
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"GcKu06QKt":{"layout":["fixed","fixed"]},"tnyKPtcg4":{"layout":["fixed","fixed"]},"DxUcpVEIi":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"JNrqPseRc":"image"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const Framerjy9UHpTRa: React.ComponentType<Props> = withCSS(Component, css, "framer-hRGI0") as typeof Component;
export default Framerjy9UHpTRa;

Framerjy9UHpTRa.displayName = "Hero Image Card";

Framerjy9UHpTRa.defaultProps = {height: 435.5, width: 406};

addPropertyControls(Framerjy9UHpTRa, {variant: {options: ["OZtYwI1bw", "GcKu06QKt", "tnyKPtcg4", "DxUcpVEIi"], optionTitles: ["Variant 1", "Variant 2", "Variant 3", "Variant 4"], title: "Variant", type: ControlType.Enum}, JNrqPseRc: {__defaultAssetReference: "data:framer/asset-reference,pPKaFkuZHNJyuJ9odfxmKelPc.png?originalFilename=hero-image-card.png&preferredSize=auto", title: "Image", type: ControlType.ResponsiveImage}} as any)

addFonts(Framerjy9UHpTRa, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})